<template>
  <div class="p-5 font-size-30">
    <div class="font-weight-bold mb-3">Page not found</div>
    <div>This page is deprecated, deleted, or does not exist at all</div>
    <div class="font-weight-bold font-size-70 mb-1">404 —</div>
    <button @click="toCurrentDashboard" class="btn btn-outline-primary width-100">Go Back</button>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'CuiError404',
  computed: {
    ...mapState(['settings']),
  },
  methods: {
    toCurrentDashboard() {
      if (this.settings.platform === 1) {
        // vrati xml-dashboar ako je ovaj condition
        this.$router.push({ name: 'dashboard' }).catch(() => {})
      } else {
        this.$router.push({ name: 'dashboard' }).catch(() => {})
      }
    },
  },
}
</script>
